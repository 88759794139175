import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardGuard implements CanLoad, CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean> | Promise<boolean> | boolean {
    return this.validaOnboard();
  }

  canLoad(route: Route): boolean {
    return this.validaOnboard();
  }

  validaOnboard() {
    if (this.authService.getUser() && this.authService.getUser().nuDoc) {
      this.router.navigate(['/tabs']);
      return false;
    }
    return true;
  }
}
