import { Injectable } from '@angular/core';
import { ParceiroModel } from '../models/parceiro.model';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ParceiroService extends AbstractService<ParceiroModel> {

    public parceiro: ParceiroModel;

    constructor(
        public http: HttpClient
    ) {
        super('parceiro', http);
    }

    getVersao(id: string) {
        return this.http.get(this.getUrl() + `/version/${id}`)
            .pipe(map(this.extractData), catchError(this.handleError));
    }

    getById(id: string) {
        return super.getById(id);
    }

}
