import { Component } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import ptBr from '@angular/common/locales/pt';
import en from '@angular/common/locales/en';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NotificacaoService } from './shared/services/notificacao.service';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private swUpdate: SwUpdate,
    private router: Router,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private notificationService: NotificacaoService,
    private title: Title
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.registerLocale();
      this.checkForUpdate();
      this.fireBaseAnalytics();
      if (this.platform.is('hybrid')) {
        this.notificationService.startNotificacao();
        this.notificationService.localNotificationReceived();
        window.screen.orientation.lock('portrait');
        SplashScreen.hide();
        setTimeout(() => {
          this.configStatusBar();
        }, 5000);
      }
    });
  }

  private registerLocale() {
    const locale = this.translate.getDefaultLang();
    if (locale === 'pt') {
      registerLocaleData(ptBr);
    }
    if (locale === 'es') {
      registerLocaleData(es);
    }
    if (locale === 'en') {
      registerLocaleData(en);
    }
  }

  fireBaseAnalytics() {
    if (!this.platform.is('hybrid')) {
      return;
    }
    this.router.events
    .subscribe(event => {
      // observe router and when it start navigation it will track the view
      if (event instanceof NavigationStart) {
        let title = this.title.getTitle();
        // get title if it was sent on state
        if (this.router.getCurrentNavigation().extras.state) {
          title = this.router.getCurrentNavigation().extras.state.title;
        }
        // pass url and page title
        if (event.url !== '') { // Não trackea vazio para não gerar inconsistencia
          FirebaseAnalytics.logEvent({
            name: 'Navegação',
            params: {
              url: event.url,
              page: title,
            }
          });
        }
      }
    });
  }

  checkForUpdate() {
    // tslint:disable-next-line:no-console
    console.info('check for update sem refresh');
    this.swUpdate.available.subscribe(event => {
      this.confirmUpdateApp();
      // tslint:disable-next-line:no-console
      console.info('current version is ', event.current, ' available version is ', event.available);
    });
    this.swUpdate.activated.subscribe(event => {
      // tslint:disable-next-line:no-console
      console.info('old version was ', event.current, ' new version is ', event.previous);
    });
  }

  async confirmUpdateApp() {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('ATUALIZACAO_DISPONIVEL_TITULO'),
      message: this.translate.instant('ATUALIZACAO_DISPONIVEL'),
      cssClass: 'alert-custom',
      mode: 'md',
      buttons: [
        {
          text: this.translate.instant('NAO'),
          cssClass: 'half default',
          role: 'cancel',
        }, {
          text: this.translate.instant('SIM'),
          cssClass: 'half primary',
          handler: () => {
            window.location.reload();
          }
        }
      ]
    });
    await alert.present();
  }

  /**
   * Configurar statusbar
   */
  private configStatusBar() {
    if (this.platform.is('capacitor')) {
      console.log('configStatusBar');
      if (this.platform.is('android')) {
        console.log('android');
        StatusBar.setStyle({style: Style.Dark});
        StatusBar.setBackgroundColor({color: '#0076BB'});
      } else {
        console.log('ios');
        StatusBar.setStyle({style: Style.Light});
      }
    }
  }
}
