import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreAuthGuard implements CanLoad {

  constructor(
    private auth: AuthService,
    private router: Router
  ) {
  }

  async canLoad(route: Route): Promise<boolean> {
    const isAuthenticated = this.auth.isAuthenticated();
    if (isAuthenticated && !this.auth.getUser().nuDoc) {
      await this.router.navigate(['/onboard']);
      return false;
    }
    if (isAuthenticated && this.auth.getUser().nuDoc) {
      await this.router.navigate(['/tabs']);
      return false;
    }
    return true;
  }
}
