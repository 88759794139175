import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { isAfter, isEqual, parseISO, startOfDay } from 'date-fns';
import { toast } from './toast';

export function compareTextIndexOf(str1, str2) {
  return removerAcentos(str1).toLowerCase().indexOf(removerAcentos(str2).toLowerCase()) > -1;
}

export function removerAcentos(newStringComAcento) {
  let str = (newStringComAcento).toString();
  const mapaAcentosHex = {
    a: /[\xE0-\xE6]/g,
    e: /[\xE8-\xEB]/g,
    i: /[\xEC-\xEF]/g,
    o: /[\xF2-\xF6]/g,
    u: /[\xF9-\xFC]/g,
    c: /\xE7/g,
    n: /\xF1/g
  };

  for (const letra in mapaAcentosHex) {
    const expressaoRegular = mapaAcentosHex[letra];
    str = str.replace(expressaoRegular, letra);
  }
  return str;
}

export function getAppUrl() {
  const baseUrl = getCookie('baseUrl');
  return baseUrl ? baseUrl : environment.apiUrl;
}

export function getCookie(cname) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    // tslint:disable-next-line:triple-equals
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    // tslint:disable-next-line:triple-equals
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function getDataLabelsFromArray(data: any[], labelKey: string) {
  const labels = [];
  data.forEach(d => {
    if (d[labelKey]) {
      labels.push(
        moment(d[labelKey]).utcOffset('America/Sao_Paulo').format('DD/MM/YYYY')
      );
    } else {
      console.warn(`Key ${labelKey} not found in array`);
    }
  });
  return labels;
}


export function removeAcentos(value: string) {
  const mapHexAcentos = {
    a: /[\xE0-\xE6]/g,
    A: /[\xC0-\xC6]/g,
    e: /[\xE8-\xEB]/g,
    E: /[\xC8-\xCB]/g,
    i: /[\xEC-\xEF]/g,
    I: /[\xCC-\xCF]/g,
    o: /[\xF2-\xF6]/g,
    O: /[\xD2-\xD6]/g,
    u: /[\xF9-\xFC]/g,
    U: /[\xD9-\xDC]/g,
    c: /\xE7/g,
    C: /\xC7/g,
    n: /\xF1/g,
    N: /\xD1/g,
  };

  // tslint:disable-next-line
  for (const char in mapHexAcentos) {
    const regExp = mapHexAcentos[char];

    value = value.replace(regExp, char);
  }

  return value;
}


export function getDataFromArray(data: any[], labelKey: string) {
  const res = [];
  data.forEach(d => {
    if (d[labelKey]) {
      res.push(
        d[labelKey]
      );
    } else {
      console.warn(`Key ${labelKey} not found in array`);
    }
  });
  return res;
}

export function calculoIMC(peso, altura) {
  const imc = peso && altura ? (peso / (altura * altura)).toFixed(2) : '-';
  return imc;
}

export function labelIMC(peso, altura) {
  const imc: any = peso && altura ? (peso / (altura * altura)).toFixed(1) : null;
  if (!imc) {
    return '-';
  }

  if (imc < 18.5) {
    return 'ABAIXO_DO_PESO';
  } else if (imc >= 18.5 && imc <= 24.9) {
    return 'PESO_NORMAL';
  } else if (imc >= 25 && imc <= 29.9) {
    return 'SOBREPESO';
  } else if (imc >= 30 && imc <= 34.9) {
    return 'OBESIDADE_GRAU_I';
  } else if (imc >= 35 && imc <= 39.9) {
    return 'OBESIDADE_GRAU_II';
  } else if (imc > 39.9) {
    return 'OBESIDADE_GRAU_III';
  }
}

export function cssIMC(peso, altura) {
  const imc: any = peso && altura ? (peso / (altura * altura)).toFixed(1) : 0;

  if (!imc) {
    return 'green';
  }

  if (imc < 18.5) {
    return 'yellow';
  } else if (imc >= 18.5 && imc <= 24.9) {
    return 'green';
  } else if (imc >= 25 && imc <= 29.9) {
    return 'yellow';
  } else if (imc >= 30 && imc <= 34.9) {
    return 'orange';
  } else if (imc >= 35 && imc <= 39.9) {
    return 'red';
  } else if (imc > 39.9) {
    return 'red';
  }
}

export function isDateEqual(date1: Date, date2?: Date) {
  let compare = startOfDay(new Date());
  if (date2) {
    compare = startOfDay(date2);
  }
  // @ts-ignore
  return isAfter(parseISO(date1), compare) || isEqual(parseISO(date1), compare);
}

export function diffDate(date1: any, date2: any) {
  return new Date(date1).getTime() - new Date(date2).getTime();
}

export function calcularIdade(data: any) {
  const hoje = new Date();
  const dtNascimento = new Date(data);
  let diferencaAnos = hoje.getFullYear() - dtNascimento.getFullYear();
  if (new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) <
    new Date(hoje.getFullYear(), dtNascimento.getMonth(), dtNascimento.getDate())) {
    diferencaAnos--;
  }
  return diferencaAnos;
}

export function copyText(val: string, element: string, hasToast = true) {
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = val;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
  if (hasToast) toast(element + ' copiado para área de transferência');
}

export function firstFileToBase64(fileImage: any): Promise<{}> {
  return new Promise((resolve, reject) => {
    const fileReader: FileReader = new FileReader();
    if (fileReader && fileImage != null) {
      fileReader.readAsDataURL(fileImage);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    } else {
      reject(new Error('No file found'));
    }
  });
}

export const formatoNumeral = (value: string, maxFractionDigits?: number) => {
  if (!value) {
    return value;
  }

  if ((value.length === 1 && value[0] !== '0') || (value.length === 2)) {
    value = '00' + value;
  }
  if ((value === '000') || (value === '0')) {
    value = '0';
  }
  if (value.length === 5 && value[0] === '0' && value[1] === '0') {
    value = value.substring(2, value.length);
  }
  if (value !== '') {
    value = value.substring(0, value.length - 2) + ',' + value.substring(value.length - 2, value.length);
  }
  value = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  return value;
};

export const cleanFloat = (value: string = ''): number => {
  value = value.replace(/\D/g, '');
  value = value.length < 3 ? ('00' + value).slice(-3) : value;
  const decimalRE = new RegExp('^(\\d{' + (value.length - 2).toString() + '})(\\d{2})$');
  return parseFloat(value.replace(decimalRE, '$1.$2'));
};

export const currencyMask = (value: number = 0): any => {
  return numberToCurrency(value);
};

export const numberToCurrency = (n): any => {
  // tslint:disable-next-line:one-variable-per-declaration
  let c, d, t, s, i, j;
  c = isNaN(c = Math.abs(c)) ? 2 : c;
  d = d === undefined ? ',' : d;
  t = t === undefined ? '.' : t;
  s = n < 0 ? '-' : '';
  i = parseInt(n = Math.abs(+n || 0).toFixed(c), 10) + '';
  j = (j = i.length) > 3 ? j % 3 : 0;
  return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
}
