import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { from, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Usuario } from '../models/usuario.model';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { Platform } from '@ionic/angular';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';


export const TOKEN_AUTH = 'token_br3';
export const BR3_USER = 'user_br3';
export const BR3_LAST_USER = 'last_user_br3';

export class Menu {
  title: string;
  functionality?: string;
  url?: string;
  icon?: string;
  onlyAdmin: boolean;
  simpleUser?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  $userData: BehaviorSubject<any> = new BehaviorSubject({});
  jwtHelper = new JwtHelperService();
  private user$: Subject<boolean> = new Subject<boolean>();

  constructor(public http: HttpClient, private platform: Platform) {
  }

  isAuthenticated() {
    const token = this.getToken();

    if (!token) {
      return false;
    }

    return !this.jwtHelper.isTokenExpired(token);
  }

  checkRegister(cpf) {
    return this.http.get(`${environment.apiUrl}/br3/pacientes/cpf/${cpf}`);
  }

  post(user: Usuario) {
    return this.http.post(`${environment.apiUrl}/auth`, user);
  }

  login(token: string, rede: string, isSocial?: boolean): Observable<any> {
    const url = isSocial ? 'authenticate-social' : 'login';
    const params = isSocial ? {token, rede} : {email: token, password: rede };
    return from(new Promise((resolve, reject) => {
      this.http.post(`${environment.apiUrl}/auth/${url}`, params)
        .subscribe(async (data: Usuario) => {
          this.setToken(data.access_token);
          this.setUser(data);
          resolve(data);
        }, reject);
    }));
  }

  alterarPhoto(user: Usuario) {
    return this.http.post(`${environment.apiUrl}/auth/photo/${user._id}`, user);
  }

  atualizarSenha(user: Usuario) {
    return this.http.put(`${environment.apiUrl}/auth/change-password`, user);
  }

  enviarCodigoPorEmail(email: string) {
    return this.http.post(`${environment.apiUrl}/auth/code-recovery`, {email});
  }

  enviarCodigoPorCPF(cpf: string) {
    return this.http.post(`${environment.apiUrl}/auth/code-recovery`, {cpf});
  }

  resetarSenha(user: { codigoRecuperacao: string; password: string; email: string }) {
    return this.http.post(`${environment.apiUrl}/auth/reset-password`, user);
  }

  loggedIn() {
    this.user$.next(true);
  }

  isLoggedIn() {
    return this.user$;
  }

  setUser(user: Usuario) {
    delete user.password;
    this.$userData.next(user);
    localStorage.setItem(BR3_USER, JSON.stringify(user));
    localStorage.setItem(BR3_LAST_USER, user.email);
  }

  getUser(): Usuario {
    if (!localStorage.getItem(BR3_USER)) {
      return null;
    }
    return JSON.parse(localStorage.getItem(BR3_USER));
  }

  setToken(token: string) {
    return localStorage.setItem(TOKEN_AUTH, token);
  }

  getToken(): string {
    return localStorage.getItem(TOKEN_AUTH);
  }

  public nextUser() {
    this.$userData.next(this.getUser());
  }

  async clearAuthorizationToken() {

    if (this.getUser().google && this.platform.is('hybrid')) {
      try {
        await GoogleAuth.signOut();
      } catch (e) {
      }
    }
    if (this.getUser().facebook && this.platform.is('hybrid')) {
      try {
        await FacebookLogin.logout();
      } catch (e) {
      }
    }

    localStorage.removeItem(TOKEN_AUTH);
    localStorage.removeItem(BR3_USER);
    localStorage.removeItem(TOKEN_AUTH);
    localStorage.removeItem('_capuid');
    // horse para limpar o DOM
    window.location.reload();
  }

  verificarDadosQrCode(params) {
    return this.http
      .get<any>(`${environment.apiUrl}/auth/paciente/qrcode/check/${params}`);
  }
}

