import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Storage } from '@ionic/storage';
import { ParceiroService } from './shared/services/parceiro.service';

@Injectable()
export class AppInitialize {

  constructor(
    private parceiroService: ParceiroService,
    public storage: Storage
  ) {
  }

  async init() {
    // Seta o tema de acordo com o parceiro
    // const theme = document.querySelector('body');
    // theme.style.setProperty('--ion-color-primary', this.parceiroService.parceiro.corPrincipal);
    // theme.style.setProperty('--ion-color-secondary', this.parceiroService.parceiro.corSecundaria);
  }

}
