import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PreAuthGuard } from './shared/guards/pre-auth.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { OnboardGuard } from './shared/guards/onboard.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'style-guide',
    loadChildren: () => import('./style-guide/style-guide.module').then(m => m.StyleGuidePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    canLoad: [PreAuthGuard]
  },
  {
    path: 'onboard',
    loadChildren: () => import('./onboard/onboard.module').then(m => m.OnboardPageModule),
    canLoad: [OnboardGuard, AuthGuard]
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'my-health',
    loadChildren: () => import('./my-health/my-health.module').then(m => m.MyHealthPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'symptoms',
    loadChildren: () => import('./symptoms/symptoms.module').then(m => m.SymptomsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'result-symptoms',
    loadChildren: () => import('./result-symptoms/result-symptoms.module').then(m => m.ResultSymptomsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'config-privacy',
    loadChildren: () => import('./config-privacy/config-privacy.module').then(m => m.ConfigPrivacyPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'sticky-notes',
    loadChildren: () => import('./sticky-notes/sticky-notes.module').then(m => m.StickyNotesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'doctors',
    loadChildren: () => import('./doctors/doctors.module').then(m => m.DoctorsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'exam-history',
    loadChildren: () => import('./exam-history/exam-history.module').then(m => m.ExamHistoryPageModule)
  },
  {
    path: 'patient-care',
    loadChildren: () => import('./patient-care/patient-care.module').then(m => m.PatientCarePageModule)
  },
  {
    path: 'patient-care-detail/:idCare',
    loadChildren: () => import('./patient-care-detail/patient-care-detail.module').then(m => m.PatientCareDetailPageModule)
  },
  {
    path: 'patient-care-phase/:idCare/:phase',
    loadChildren: () => import('./patient-care-phase/patient-care-phase.module').then(m => m.PatientCarePhasePageModule)
  },
  {
    path: 'scheduling',
    loadChildren: () => import('./scheduling/scheduling.module').then(m => m.SchedulingPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'feed',
    loadChildren: () => import('./feed/feed.module').then(m => m.FeedPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'schedule-detail/:idAgendamento',
    loadChildren: () => import('./schedule-detail/schedule-detail.module').then(m => m.ScheduleDetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'schedule-history',
    loadChildren: () => import('./schedule-history/schedule-history.module').then(m => m.ScheduleHistoryPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'schedule-detail-finished/:idAgendamento',
    loadChildren: () => import('./schedule-detail-finished/schedule-detail-finished.module').then(m => m.ScheduleDetailFinishedPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'my-vouchers',
    loadChildren: () => import('./my-vouchers/my-vouchers.module').then(m => m.MyVouchersPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'get-voucher',
    loadChildren: () => import('./get-voucher/get-voucher.module').then(m => m.GetVoucherPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'chat/:idChat',
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'chats',
    loadChildren: () => import('./chats/chats.module').then(m => m.ChatsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'request-exam',
    loadChildren: () => import('./request-exam/request-exam.module').then(m => m.RequestExamPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./edit-profile/edit-profile.module').then(m => m.EditProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'my-wallet',
    loadChildren: () => import('./my-wallet/my-wallet.module').then(m => m.MyWalletPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'passports',
    loadChildren: () => import('./passports/passports.page.module').then(m => m.PassportsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'exame-imagem',
    loadChildren: () => import('./exame-imagem/exame-imagem.module').then( m => m.ExameImagemPageModule),
    canLoad: [AuthGuard]
  },
  {path: '**', redirectTo: 'tabs'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
