import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Paginate} from '../models/paginate.model';
import {getAppUrl} from '../util/utils';
import {catchError, map, retryWhen} from 'rxjs/operators';
import {genericRetryStrategy} from '../util/retry-strategy';

export interface RepositoryInterface<T> {
  getAll(): Observable<T[]>;

  getById(id: number): Observable<T>;

  post(entity: T): Observable<T>;

  put(entity: any, id: number): Observable<T>;

  delete(id: number): Observable<any>;
}

export abstract class AbstractService<T> implements RepositoryInterface<T> {

  public baseUrl: string;
  excludedStatusCodes = [500, 403, 401, 400];


  protected constructor(baseUrl: string, public http: HttpClient) {
    this.baseUrl = baseUrl;
  }

  public getAll(relativeUrl?: string): Observable<T[]> {
    return this.http.get<T[]>(this.getUrl(relativeUrl), this.headers()).pipe(
      map(this.extractData),
      retryWhen(genericRetryStrategy({
        scalingDuration: 2000,
        excludedStatusCodes: this.excludedStatusCodes
      })),
      catchError(this.handleError)
    );
  }

  public getAllPaged(params?: any, relativeUrl?: string): Observable<Paginate<T>[]> {
    return this.http.get<Paginate<T>[]>(this.getUrl(relativeUrl), {params})
      .pipe(
        map(this.extractData),
        retryWhen(genericRetryStrategy({
          scalingDuration: 2000,
          excludedStatusCodes: this.excludedStatusCodes
        })),
        catchError(this.handleError)
      );
  }

  public getById(id?: number | string, relativeUrl?: string): Observable<T> {
    const strId = id ? `${id}` : '';
    let url = `${strId}`;
    if (relativeUrl) {
      url = `${relativeUrl}/${strId}`;
    }
    return this.http.get<T>(this.getUrl(url), this.headers()).pipe(
      map(this.extractData),
      retryWhen(genericRetryStrategy({
        scalingDuration: 2000,
        excludedStatusCodes: this.excludedStatusCodes
      })),
      catchError(this.handleError)
    );
  }

  public post(entity: T, relativeUrl?: string): Observable<T> {
    return this.http.post<T>(this.getUrl(relativeUrl), entity, this.headers()).pipe(
      map(this.extractData),
      retryWhen(genericRetryStrategy({
        scalingDuration: 2000,
        excludedStatusCodes: this.excludedStatusCodes
      })),
      catchError(this.handleError)
    );
  }

  public put(entity: T, id: number | string, relativeUrl?: string): Observable<T> {
    const strId = id ? `${id}` : '';
    let url = `${strId}`;
    if (relativeUrl) {
      url = `${relativeUrl}/${strId}`;
    }
    return this.http.put<T>(
      this.getUrl(url), entity, this.headers()).pipe(
      map(this.extractData),
      retryWhen(genericRetryStrategy({
        scalingDuration: 2000,
        excludedStatusCodes: this.excludedStatusCodes
      })),
      catchError(this.handleError)
    );
  }

  public delete(id: any, relativeUrl?: string): Observable<any> {
    const strId = id ? `${id}` : '';
    let url = `${strId}`;
    if (relativeUrl) {
      url = `${relativeUrl}/${strId}`;
    }
    return this.http.delete(this.getUrl(url), this.headers()).pipe(
      retryWhen(genericRetryStrategy({
        scalingDuration: 2000,
        excludedStatusCodes: this.excludedStatusCodes
      })),
      catchError(this.handleError)
    );
  }

  protected getUrl(relativeUrl?: string): string {
    let absoluteUrl = `${getAppUrl()}/${this.baseUrl}`;
    const path = /^(http|https):\/\//g;
    if (path.test(this.baseUrl)) {
      absoluteUrl = this.baseUrl;
    }
    if (relativeUrl !== null && relativeUrl !== undefined) {
      if (path.test(relativeUrl)) {
        return relativeUrl;
      }
      absoluteUrl += `/${relativeUrl}`;
    }
    return absoluteUrl;
  }

  protected handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(error.error);
  }

  protected headers() {
    return {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
  }

  protected extractData(res: any) {
    let body: any = res;
    if ('resposta' in body) {
      body = body.resposta;
    }
    return body;
  }
}
