import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Notificacao } from '../models/notificacao.model';
import { storage } from 'src/app/shared/util/storage';
import { ILocalNotification, LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { mergeMapTo } from 'rxjs/operators';
import { Browser } from '@capacitor/browser';
import {
  ActionPerformed,
  PushNotification,
  PushNotificationActionPerformed,
  PushNotifications,
  PushNotificationToken
} from '@capacitor/push-notifications';

const UUIDKey = 'ia-uidd-pwa';

@Injectable({
  providedIn: 'root'
})
export class NotificacaoService {

  constructor(
    private alertCtrl: AlertController,
    private platform: Platform,
    private afMessaging: AngularFireMessaging,
    private angularFireMessaging: AngularFireMessaging,
    private router: Router,
    private localNotifications: LocalNotifications
  ) {
  }

  notificacoes$ = new Subject<Notificacao[]>();

  public gerarId(): number {
    const min = Math.ceil(1);
    const max = Math.floor(9999999999999);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  async alertaNotificacao(notificacao: Notificacao) {
    const alert = await this.alertCtrl.create({
      header: 'Notificação',
      mode: 'ios',
      subHeader: notificacao.titulo,
      message: notificacao.mensagem,
      buttons: ['OK']
    });

    await alert.present();
  }

  async registrarDispositivoWeb() {
    if (!this.platform.is('hybrid')) {
      this.afMessaging.requestPermission
      .pipe(mergeMapTo(this.afMessaging.tokenChanges))
      .subscribe(
        async token => {
          console.log(
            '[PWA] = Permission granted! Save to the server!'
          );
          localStorage.setItem('token-firebase', token);
          if (token) {
            let uid = await localStorage.getItem(UUIDKey) || null;
            if (!uid) {
              uid = uuidv4();
              localStorage.setItem(UUIDKey, uid);
            }
            this.angularFireMessaging.messages.subscribe(
              (notification: any) => {
                console.log('new message received. ', notification);
              });
          }
        },
        error => {
          console.error(error);
        }
      );
      return;
    }
  }

  private notificacaoLocal() {
    if (!this.platform.is('hybrid')) {
      return;
    }
    this.localNotifications.requestPermission().then((granted) => {
      console.log('LocalNotifications requestPermission granted' + granted);
    });
    // LocalNotifications.getPending().then(d => {alert('getPending ' + JSON.stringify(d));});
  }

  async startNotificacao(notRetry?: boolean) {
    this.notificacaoLocal();
    if (!this.platform.is('hybrid')) {
      this.registrarDispositivoWeb();
      return;
    }
    PushNotifications
    .requestPermissions()
    .then((permission) => {
      if (permission.receive === 'granted') {
        this.notificacaoListeners();
        PushNotifications.register();
      } else {
        // android workaround
        if (!notRetry) {
          this.startNotificacao(true);
        }
      }
    })
    .then(() => console.log('push registrado'));
  }


  private notificacaoListeners() {
    PushNotifications.addListener('registration',
      (token: PushNotificationToken) => {
        console.log('PushNotificationToken', token);
        localStorage.setItem('token-firebase', token.value);
        console.log('Notificacao registration success, token: ' + token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.error('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotification) => {
        // @ts-ignore
        this.alertaNotificacao({
          id: notification.id,
          titulo: notification.title,
          mensagem: notification.body,
          dataRecebimento: new Date().toISOString(),
          lido: false
        });
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (action: ActionPerformed) => {
        this.router.navigate(['/tabs/notificacoes']);
        if (action.notification.data.link) {
          this.abrirLink(action.notification.data.link);
        }
      }
    );
  }

  async abrirLink(link: string) {
    const alert = await this.alertCtrl.create({
      header: 'Deseja abrir o link?',
      message: '<p>Você será redirecionado para o navegador.</p>',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Confirmar',
          handler: async () => {
            await Browser.open({url: link});
          }
        }
      ]
    });

    await alert.present();
  }


  async localNotificationReceived() {
    // this.localNotifications.addListener('localNotificationReceived',
    //   async (notification: LocalNotification) => {
    //     const alert = await this.alertCtrl.create({
    //       header: 'Lembrete',
    //       mode: 'md',
    //       cssClass: 'alert-custom',
    //       subHeader: notification.title,
    //       message: notification.body,
    //       buttons: [{
    //         text: 'Ok',
    //         cssClass: 'half primary'
    //       }]
    //     });
    //     await alert.present();
    //   });
  }
  public async notificarLocal(notifications: ILocalNotification[], idStorage: string) {
    if (!this.platform.is('hybrid')) {
      return;
    }
    await this.localNotifications.schedule(notifications);
    const ids = notifications.reduce((idsNotification: number[], notification: ILocalNotification) => {
      idsNotification.push(notification.id);
      return idsNotification;
    }, []);
    await storage.set(idStorage, ids);
  }

  public async notificarLocalUnico(notification: ILocalNotification, idStorage: string) {
    if (!this.platform.is('hybrid')) {
      return;
    }
    await this.localNotifications.schedule(notification);
    await storage.set(idStorage, [notification.id]);
  }

  async removeNotificacoes(idStorage: string) {
    if (!this.platform.is('hybrid')) {
      return;
    }
    const notificaoIds = await storage.get(idStorage);
    if (!notificaoIds) return;
    for (const id of notificaoIds) {
      await this.localNotifications.cancel(id);
    }
  }
}
