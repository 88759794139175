// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    env: 'dev',
    appUrl: 'https://hlg.health.contrictor.com.br',
    apiUrl: 'https://hlg.api-health.contrictor.com.br',
    apiMeet: 'https://hlg.meet.contrictor.com.br',
    apiBlog: 'https://blog-health.contrictor.com.br',
    firebaseConfig: {
      apiKey: "AIzaSyCMid07_07N3hmGgbsj-jiSO5ruNEzqJIw",
      authDomain: "br3health.firebaseapp.com",
      projectId: "br3health",
      storageBucket: "br3health.appspot.com",
      messagingSenderId: "642859376292",
      appId: "1:642859376292:web:6c6c5e804113bc9bfe1031",
      measurementId: "G-EST5BJGD0Y"
    }
};
