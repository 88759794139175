import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    constructor() {
    }

    getLang() {
        const lang = localStorage.getItem('lang');
        if (lang === 'pt') {
            return 'pt-BR';
        }
        if (lang === 'en') {
            return 'en-US';
        }
        if (lang === 'es') {
            return 'es-MX';
        }

        // default
        return 'pt-BR';
    }
}
