import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { toast } from '../util/toast';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  jwtHelper = new JwtHelperService();

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.authService.clearAuthorizationToken();
          toast('Sessão expirada, por favor, realize login novamente.', 'dark');
          this.router.navigate(['/login']);
        }
        return throwError(err);
      })
    );
  }

  isTokenExpired(token) {
    if (!token) {
      return false;
    }

    return this.jwtHelper.isTokenExpired(token);
  }
}
