import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './shared/util/translate-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuthorizationInterceptor } from './shared/interceptors/authorization.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage';
import { NgxCurrencyModule } from 'ngx-currency';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { LOCALE_ID } from '@angular/core';
import { SettingsService } from './shared/services/settings.service';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Health } from '@ionic-native/health/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { AppInitialize } from './app.initialize';

export function playerFactory() {
  return player;
}

export function initializeApp(appInitialize: AppInitialize) {
  return (): Promise<any> => {
    return appInitialize.init();
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      hardwareBackButton: false,
      swipeBackEnabled: false
    }),
    AppRoutingModule,
    HttpClientModule,
    NgxCurrencyModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'pt'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    LottieModule.forRoot({player: playerFactory}),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
  ],
  providers: [
    FileOpener,
    HTTP,
    SettingsService,
    LocalNotifications,
    AppInitialize,
    { provide: APP_INITIALIZER, useFactory: initializeApp, multi: true, deps: [AppInitialize] },
    {
      provide: LOCALE_ID,
      deps: [SettingsService],
      useFactory: (service) => service.getLang()
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true},
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    Health
  ],
  exports: [
    TranslateModule
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
